
.tabView__container {
  width: 100%;
}

.tabView__header {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
}

.tabView__headerItem {
  border: solid black 2px;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  cursor: pointer;
  min-width: 120px;
}

.tabView__content {
  width: 100%;
  min-height: 500px;
  border: 1px solid black;
  padding: 16px;
}
