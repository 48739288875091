@import 'variables.scss';

.container__search {

}

.container__textView {
  p {
    margin-bottom: 4px;
  }
}

.searchField {
  padding: 2px 4px;
}

.searchField--small {
  font-size: 16px;
  line-height: 20px;
}

.searchField--medium {
  font-size: 20px;
  line-height: 32px;
}

.searchField--large {
  font-size: 24px;
  line-height: 36px;
}

.searchField--fullWidth {
  width: 100%;
}

.updateableField__wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  transition: width ease-in-out .25s;
  .container__textView {
    width: 100%;
  }
  .simple-spinner {
    display: flex;
    align-self: flex-end;
    margin: 0 16px 4px 16px;
  }
}

.updateableField__wrapper--spaceBetween {
  justify-content: space-between;
  align-content: center;
}

.updateableField__warning {
  margin-top: 4px;
  color: $red;
}
