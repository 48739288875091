

.userPage {
  display: flex;
  flex-direction: column;

}

.user__search {
  max-width: 400px;
  align-self: center;
  margin-bottom: 24px;
}

.user__list {
  margin-top: 24px;
}

.user__row {
  padding: 12px 24px;
  margin-bottom: 16px;
  border: 1px solid var(--light-aluminium);
  border-radius: 8px;
  display: flex;
}

.user__controls {
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.user__controls a {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  margin-left: 16px;
}

.userItemList {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
.userItemList > div {
  margin-bottom: 24px;
}

.inviteRow {
  display: flex;
}
