@import '../components/variables';

.bulkUploadPage__container {
  margin-top: 32px;
}

.bulkUpload__textArea {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bulkUpload__instructionList {
  margin-left: 24px;
}

.bulkUpload__checkUsers {
  table {
    color: #111;
    background: white;
    border: 1px solid grey;
    font-size: 12px;
    border-collapse: collapse;
  }
  table th,
  table th {
    color: #111;
    background: rgba(0,0,0,.1);
  }
  table caption {
    padding:.5em;
  }
  table th,
  table td {
    padding: 6px 4px;
    border: 1px solid lightgrey;
  }
  tr:hover {
    background: rgba(0,0,0,.1);
  }
  .cell--important {
    background-color: rgba(255, 0, 0, 0.15);
  }
}

.bulkUpload__controls {
  margin: 16px 0 32px 0;
  display: flex;
  justify-content: space-between;
}

.bulkUpload__userRow {
  button p {
    font-size: 13px;
    line-height: 15px;
  }
}
