

.companyQuickInfo {
  margin-bottom: 32px;
}

.company__buttonRow {
  display: flex;
}

.company__buttonRow button {
  margin-left: 8px;
}

.companyQuickInfo span {
  font-size: 14px;
}

.companyControls {
  margin-bottom: 32px;
}

.company__adminSettings {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 50px;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 50px;
}

.company__adminSettingsRow {
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.rdp__wrapper {
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid black;
  z-index: 99999999;
}

.rdp button {
  min-width: unset !important;
}
