
.dialogContent {
  padding: 24px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}

.dialog__controls {
  margin: 36px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.dialog__field {
  margin-bottom: 32px !important;
}
