
.userList__container {
  max-width: 1080px;
}

.userList__header {
  display: flex;
  background-color: var(--light-aluminium);
  padding: 8px 2px;
}

.userList__dateTime {
  width: 20%;
}

.userList__state {
  width: 15%;
}

.userList__link {
  width: 65%;

}

.userList__row {
  display: flex;
  padding: 8px 2px;
  border-bottom: 1px solid var(--light-aluminium);
}
