

.container__contentCards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.contentCard {
  width: 100%;
  margin: 16px;
  background: $veryLightGrey;
  border-radius: 8px;
  border: 1px solid $mildGrey;
  padding: 16px;
  @include largeMobile {
    width: 45%;
  }
  @include tablet {
    width: 30%;
  }
}

.contentCard--fullWidth {
  width: 100%;
  margin: 16px;
  background: $veryLightGrey;
  border-radius: 8px;
  border: 1px solid $mildGrey;
  padding: 16px;
}
