@import "components/variables";
@import "general/fonts";
@import "components/content-cards";
@import "components/text-input";
@import "components/button";
@import "components/sortable-table";
@import "components/simple-spinner";
@import "pages/bulk-upload";
@import "pages/company-list";
@import "components/office-list";
@import "components/tab-view";
@import "components/drop-down";


p {
  font-size: 14px;
  font-family: sans-serif;
}
