@import '../components/variables';

.container__domainName {
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 6px;
  }
}

.domainName__controls {
  display: flex;
  align-items: center;
  width: 100%;
  .switch {
    margin-right: 16px;
  }
}

.companyView__header {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
}
