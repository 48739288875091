
.header__cell {
  display: flex;
  align-items: center;
  padding: 16px 16px 8px 0;
  p {
    padding: 0;
    margin: 0;
  }
  .header__icon {
    margin-left: 8px;
  }
}

.sortableTable tr:nth-child(even) {
  background-color: $veryLightGrey;
}

.sortableTable tr:hover {
  background-color: $lightGrey;
}

.sortableTable th {
  border-bottom: 2px solid $darkGrey;
}

.sortableTable tr td {
  padding: 2px 8px;
}
