
select {
  max-width: 100%;
  width: 350px;
  outline: none;
  border: 1px solid #fff;
  padding: 1rem;
  font-size: 20px;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.329);
  cursor: pointer;
}

select:focus,
select:hover {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.8);
}
