@import '../components/variables';

.companyList {
  margin: 32px auto;
  width: 100%;
}

.companyList__wrapper {
  display: flex;
  justify-content: center;
  button {
    margin: 0 16px;
  }
}

.sortableTable__companyList table {
  margin: 16px auto;
  min-width: 960px;
}
