
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

.headline__xlarge {
  letter-spacing: -1px;
  color: $black;
  font-family: "soleil", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 48px;
  line-height: 50px;
  @include tablet {
    font-size: 62px;
    line-height: 64px;
  }
}

.headline__large {
  letter-spacing: -1px;
  color: $black;
  font-family: "soleil", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
  line-height: 38px;
  @include tablet {
    font-size: 44px;
    line-height: 46px;
  }
}

.headline__medium {
  color: $black;
  font-family: "soleil", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 32px;
  line-height: 34px;
  letter-spacing: -0.01em;
  @include tablet {
    font-size: 32px;
    line-height: 34px;
  }
}

.headline__small {
  color: $black;
  font-family: "soleil", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 26px;
}

.headline__verysmall {
  color: $black;
  font-family: "soleil", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.headline__xsmall {
  font-family: "soleil", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.01em;
}

.body__regular {
  color: $black;
  font-size: 18px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 0;
  @include tablet {
    font-size: 18px;
    line-height: 34px;
  }
}

.body__xxsmall {
  color: $black;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.body--bold {
  font-weight: 600;
}

.body__xsmall {
  color: $black;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  @include smallTablet {
    font-size: 16px;
    line-height: 18px;
  }
}

.body__verySmall {
  color: $black;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
}

.body__xsmall--bold {
  @extend .body__xsmall;
  font-weight: 700;
}

.body__small {
  color: $black;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
}

.body__semi {
  color: $black;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 34px;
}

.body__semi--bold {
  color: $black;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 34px;
}

.body__large {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-size: 22px;
  line-height: 34px;
  color: $black;
}

.body__large--bold {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  margin: 0;
  padding: 0;
  line-height: 34px;
  color: $black;
}

.body--error {
  color: $red;
}


// Colors
.font--green {
  color: $green;
}
.font--blue {
  color: $blue;
}
.font--darkGrey {
  color: $darkGrey;
}
.font--midGrey {
  color: $mildGrey;
}
.font--red {
  color: $red;
}
